import { createSlice } from '@reduxjs/toolkit';

export const slimesItem = createSlice({
	name: 'slimesItem',
	initialState: {
		value: {} as Record<string, any>,
	},
	reducers: {
		addSlimeItem: (state, action) => {
			const newItem = action.payload;
			const isItemExist = state.value?.[newItem.id];
			if (isItemExist === undefined) {
				state.value = {
					...state.value,
					[newItem.id]: {
						image: newItem.image,
						name: newItem.name,
						attributes: newItem.attributes,
					},
				};
			}
		},
	},
});

export const { addSlimeItem } = slimesItem.actions;
