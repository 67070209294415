/** @format */

// store.js
import { configureStore } from "@reduxjs/toolkit";
import { counterSlice } from "../slice/Audio/counterSlice";
import { accountWalletInfo } from "../slice/walletAccount/accountInfoSlice";
import { slimesItem } from "../slice/slimes/slimesItem";
import { coinData } from "../slice/walletAccount/coinValue";
import { accountProfile } from "../slice/walletAccount/accountProfile";
import { dayRewards } from "../slice/dayRewards/dayRewards";

export const store = configureStore({
	reducer: {
		counter: counterSlice.reducer,
		accountWallet: accountWalletInfo.reducer,
		slimesItem: slimesItem.reducer,
		coinData: coinData.reducer,
		accountProfile: accountProfile.reducer,
		dayRewards: dayRewards.reducer,
	},
});
