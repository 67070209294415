/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const accountProfile = createSlice({
	name: 'accountProfile',
	initialState: {
		value: null,
	},
	reducers: {
		setAccountProfile: (state, action) => {
			state.value = action.payload;
		},
		clearAccountProfile: (state, action) => {
			state.value = null;
		},
	},
});

export const { setAccountProfile, clearAccountProfile } = accountProfile.actions;
