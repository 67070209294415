export const ENV = process.env.NEXT_PUBLIC_ENV === "dev" ? "dev" : "prod";
//@ts-ignore
export const DEFAULT_CHAIN_ID = ENV === "dev" ? 97 : 56;
export const ITEMS_PER_PAGE = 8;
export const START_SELL = 1650020400 * 1000;
export const UNBOX_TIME = 1650448800 * 1000;
export const PUBLIC_SALE = 1650106800 * 1000;

const DEV_RPC = "https://data-seed-prebsc-1-s3.binance.org:8545/";
const PROD_RPC = "https://bsc-dataseed.binance.org/";

const DEV_SERVER_ENDPOINT = "https://beta-faucet-api.dev.chainverse.xyz";
const PROD_SERVER_ENDPOINT = "https://market-api.chainverse.org";

const DEV_SERVER_SLIME = "https://slimeroyale.com/api";
const PROD_SERVER_SLIME = "https://slimeroyale.com/api";

const DEV_SLIME_GAME = "https://api-test.slimeroyale.com";
const PROD_SLIME_GAME = "https://game.slimeroyale.com";

const DEV_API_CHECK_MAINTAIN = "https://slimeroyale.com/is-maintain-test";
const PROD_API_CHECK_MAINTAIN = "https://slimeroyale.com/is-maintain";

const DEV_API_CHECK_MAINTAIN_MARKET = "https://slimeroyale.com/is-maintain-test-market";
const PROD_API_CHECK_MAINTAIN_MARKET = "https://slimeroyale.com/is-maintain-market";

const DEV_SOCKET = "api-test.slimeroyale.com";
const PROD_SOCKET = "api.slimeroyale.com";

export const SECRET_KEY = "704e47c0e3b3dd89834ec2367af0cd47";
export const NUMBER_BLOCK = 2;

export const PARTNER_ID_DEV = "US5S8F1N4Q";
export const PARTNER_ID_PROD = "BHHCQFM1UQ";

const IGO_CONTRACT_DEV = "0x7f2F5de09710ec0533e7079bADcA0b0a9Fb99573";
const IGO_CONTRACT_PROD = "0xab228848BcbA8541f3f5792066a583b7b3238Ae9";

const MARKET_CONTRACT_DEV = "0x2ccA92F66BeA2A7fA2119B75F3e5CB698C252564";
const MARKET_CONTRACT_PROD = "0x3F06739eA30e195F6CC3Ba9E9e50028E2f354A4B";

const SLIME_CONTRACT_DEV = "0x62833F47FE1a9ea1537379161fCb82fe93B6B758";
// const SLIME_CONTRACT_DEV = '0x3e11A9eeBeF8e1877B9679c6ed8A6E2B62effE8c';
const SLIME_CONTRACT_PROD = "0xD2a1D5F1e68f6B97387C2e33CCA9284568772d22";

const WALLET_ADDRESS_PROD = "0x8fCB82f0383af1AD49b135E68552dF8828745064";
const WALLET_ADDRESS_DEV = "0x8fCB82f0383af1AD49b135E68552dF8828745064";

export const WALLET_ADDRESS =
	//@ts-ignore
	ENV === "dev" ? WALLET_ADDRESS_DEV : WALLET_ADDRESS_PROD;

const WALLET_BUY_BACK_DEV = "0xe2e3500e5fC1B7B222892Dd89Eed8b13F5734f26";
const WALLET_BUY_BACK_PROD = "0x8fCB82f0383af1AD49b135E68552dF8828745064";
export const WALLET_BUY_BACK =
	//@ts-ignore
	ENV === "dev" ? WALLET_BUY_BACK_DEV : WALLET_BUY_BACK_PROD;
const WALLET_IDO_PROD = "0x8fCB82f0383af1AD49b135E68552dF8828745064";
const WALLET_IDO_DEV = "0xe8f806Eb53251f6f86fc045Fd18a46E0EFF187cB";

export const WALLET_IDO =
	//@ts-ignore
	ENV === "dev" ? WALLET_IDO_DEV : WALLET_IDO_PROD;

const USDT_ADDRESS_DEV = "0xe1Df8B289794a9e795141C3dE09a2fF4F3295e69";
const USDT_ADDRESS_PROD = "0x55d398326f99059ff775485246999027b3197955";

export const AGENCY_ADDRESS = "0xFc42365d9d4512569FE3858F4e6695635C19E314";

export const LIST_RPC_TESTNET = [
	"https://data-seed-prebsc-2-s2.binance.org:8545",
	"https://data-seed-prebsc-1-s1.binance.org:8545",
	"https://data-seed-prebsc-1-s3.binance.org:8545",
	"https://data-seed-prebsc-2-s1.binance.org:8545",
	"https://data-seed-prebsc-1-s2.binance.org:8545",
];

export const LIST_RPC_MAINNET = [
	"https://bsc-dataseed1.binance.org",
	"https://bsc-dataseed2.binance.org",
	"https://bsc-dataseed3.binance.org",
	"https://bsc-dataseed4.binance.org",
	"https://bsc-dataseed1.defibit.io",
	"https://bsc-dataseed2.defibit.io",
	"https://bsc-dataseed3.defibit.io",
	"https://bsc-dataseed4.defibit.io",
	"https://bsc-dataseed1.ninicoin.io",
	"https://bsc-dataseed2.ninicoin.io",
	"https://bsc-dataseed3.ninicoin.io",
	"https://bsc-dataseed4.ninicoin.io",
];

export const NATIVE_COIN = "0x0000000000000000000000000000000000000000";

export const COUNTDOWN_TEXT = "PRE-SALE STARTS IN";

//@ts-ignore
export const SERVER_SOCKET = ENV === "dev" ? DEV_SOCKET : PROD_SOCKET;

export const SERVER_URI =
	//@ts-ignore
	ENV === "dev" ? DEV_SERVER_ENDPOINT : PROD_SERVER_ENDPOINT;

export const SERVER_SLIME =
	//@ts-ignore
	ENV === "dev" ? DEV_SERVER_SLIME : PROD_SERVER_SLIME;

export const SERVER_GAME =
	//@ts-ignore
	ENV === "dev" ? DEV_SLIME_GAME : PROD_SLIME_GAME;
export const IGO_CONTRACT =
	//@ts-ignore
	ENV === "dev" ? IGO_CONTRACT_DEV : IGO_CONTRACT_PROD;

export const SLIME_CONTRACT =
	//@ts-ignore
	ENV === "dev" ? SLIME_CONTRACT_DEV : SLIME_CONTRACT_PROD;

//@ts-ignore
export const RPC = ENV === "dev" ? DEV_RPC : PROD_RPC;

//@ts-ignore
export const PARTNER_ID = ENV === "dev" ? PARTNER_ID_DEV : PARTNER_ID_PROD;

export const USDT_ADDRESS =
	//@ts-ignore
	ENV === "dev" ? USDT_ADDRESS_DEV : USDT_ADDRESS_PROD;

//@ts-ignore
export const API_CHECK_MAINTAIN = ENV === "dev" ? DEV_API_CHECK_MAINTAIN : PROD_API_CHECK_MAINTAIN;

//@ts-ignore
export const API_CHECK_MAINTAIN_MARKET = ENV === "dev" ? DEV_API_CHECK_MAINTAIN_MARKET : PROD_API_CHECK_MAINTAIN_MARKET;

export const USDT_TESTNET = {
	currency: "0xe1Df8B289794a9e795141C3dE09a2fF4F3295e69",
	decimal: 18,
	symbol: "USDT",
};

export const USDT_MAINNET = {
	currency: "0x55d398326f99059fF775485246999027B3197955",
	decimal: 18,
	symbol: "USDT",
};

const SRG_DEV = `0x2b7968f29c65114eE4d511A02c5bAD09ABB233c8`;
const SRG_PROD = `0xc0FF232D16B415FE7A0D3842d31280778a9400eF`;

const SCE_DEV = `0x0281E5AD7D4C532d146540e87Cca2eB09085E59F`;
const SCE_PROD = `0xc94898c7d5e9f3e62a98995985f9a957b592140b`;

const BREED_CONTRACT_DEV = `0xb5662037D36c1ddBE93eD795E1c9891201AA14Bc`;
const BREED_CONTRACT_PROD = `0x0DbA15Ebc4601D66fF72Ec943f323088b0eaB84E`;

const CLAIM_SCE_CONTRACT_DEV = `0x0281E5AD7D4C532d146540e87Cca2eB09085E59F`;
const CLAIM_SCE_CONTRACT_PROD = `0x5110f905372B9De1793CFf1F16822b35Ec15F38c`;

const AWAKEN_CONTRACT_DEV = `0xf5E84f57f6b3898f1d711b0Ad9EacEC2dd4b0216`;
const AWAKEN_CONTRACT_PROD = `0xf5E84f57f6b3898f1d711b0Ad9EacEC2dd4b0216`;

const JWT_SIGNING_DEV = `slime@royale2xw=s*g)h$k%+-jjd(2bu!d(5r%-7if4)1ffqjhqcrop`;
const JWT_SIGNING_PROD = `slime@royale2xw=s*g)h$k%+-jjd(2bu!d(5r%-7if4)1ffqjhqcrop`;

const VESTING_CONTRACT_DEV = `0xf32d2ab04b48371F25925dca0ae2d6c1184456e3`;
const VESTING_CONTRACT_PROD = `0x92Ec31688D64206FfaD071627Ce0C228670DF304`;

const STAKING_CONTRACT_DEV = `0xC6327a100BC538e3CD528Da1397A8D46677b955f`;
const STAKING_CONTRACT_PROD = `0x5202C13da618BDdb35d46C88Fe365be1767733Ce`;

const SRG_POLL_CONTRACT_DEV = `0xDC2c702f9d36A2fce449d51Df596b2Ec6A8bf9b0`;
const SRG_POLL_CONTRACT_PROD = `0xda15922b954EC711ABd96EdbB5fCE750aFC6a1aa`;
// @ts-ignore
export const SRG_POLL_CONTRACT = ENV === "dev" ? SRG_POLL_CONTRACT_DEV : SRG_POLL_CONTRACT_PROD;

// @ts-ignore
export const STAKING_CONTRACT = ENV === "dev" ? STAKING_CONTRACT_DEV : STAKING_CONTRACT_PROD;

// @ts-ignore
export const VESTING_CONTRACT = ENV === "dev" ? VESTING_CONTRACT_DEV : VESTING_CONTRACT_PROD;
// @ts-ignore
export const SRG = ENV === "dev" ? SRG_DEV : SRG_PROD;
// @ts-ignore

export const SCE = ENV === "dev" ? SCE_DEV : SCE_PROD;
// @ts-ignore

export const BREED_CONTRACT = ENV === "dev" ? BREED_CONTRACT_DEV : BREED_CONTRACT_PROD;
// @ts-ignore

export const CLAIM_SCE_CONTRACT = ENV === "dev" ? CLAIM_SCE_CONTRACT_DEV : CLAIM_SCE_CONTRACT_PROD;
// @ts-ignore
export const CURENCY = ENV === "dev" ? USDT_TESTNET : USDT_MAINNET;
//@ts-ignore
export const MARKET = ENV === "dev" ? MARKET_CONTRACT_DEV : MARKET_CONTRACT_PROD;

// @ts-ignore

export const AWAKEN_CONTRACT = ENV === "dev" ? AWAKEN_CONTRACT_DEV : AWAKEN_CONTRACT_PROD;
// @ts-ignore
export const JWT_SIGNING = ENV === "dev" ? JWT_SIGNING_PROD : JWT_SIGNING_PROD;

export const API_TRANSFER = [
	{
		inputs: [
			{
				internalType: "string",
				name: "name_",
				type: "string",
			},
			{
				internalType: "string",
				name: "symbol_",
				type: "string",
			},
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "owner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
		],
		name: "Approval",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "from",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "to",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
		],
		name: "Transfer",
		type: "event",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "owner",
				type: "address",
			},
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
		],
		name: "allowance",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "approve",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "balanceOf",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "decimals",
		outputs: [
			{
				internalType: "uint8",
				name: "",
				type: "uint8",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "subtractedValue",
				type: "uint256",
			},
		],
		name: "decreaseAllowance",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "addedValue",
				type: "uint256",
			},
		],
		name: "increaseAllowance",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "name",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "symbol",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "totalSupply",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "recipient",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "transfer",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "sender",
				type: "address",
			},
			{
				internalType: "address",
				name: "recipient",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "transferFrom",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
];

const LOTTERY_ID_DEV = {
	DAILY: "634af64245d6d01ec8012b98",
	WEEKLY: "634af62645d6d01ec8012b94",
	FINAL: "634af60a45d6d01ec8012b90",
};

const LOTTERY_ID_PROD = {
	DAILY: "634d2ff573d0cbfd75bc0cce",
	WEEKLY: "634d2fe873d0cbfd75bc0cca",
	FINAL: "634d2fd773d0cbfd75bc0cc6",
};

const NODE_URL_PROD = "https://slimeroyale.com/api";
const NODE_URL_DEV = "https://slimeroyale.com/api";

const TIME_STAKING_DEV = 2160;
const TIME_STAKING_PROD = 31104000;

// @ts-ignore
export const TIMES_TAKING = ENV === "dev" ? TIME_STAKING_DEV : TIME_STAKING_PROD;

// @ts-ignore
export const LOTTERY_ID = ENV === "dev" ? LOTTERY_ID_DEV : LOTTERY_ID_PROD;

// @ts-ignore
export const NODE_URL = ENV === "dev" ? NODE_URL_DEV : NODE_URL_PROD;

const PRIZE_ID_DEV = {
	DAILY_200: "6385b0a3e6e98f5e485f71cc",
	DAILY_1K: "6385b0b2e6e98f5e485f71cf",
	WEEKLY: "6385b0d3e6e98f5e485f71d2",
	FINAL: "6385b0ebe6e98f5e485f71d5",
};

const PRIZE_ID_PROD = {
	DAILY_200: "638b9c662c0e5e4802976d64",
	DAILY_1K: "638b9c702c0e5e4802976d67",
	WEEKLY: "638b9cc12c0e5e4802976d6d",
	FINAL: "638b9ce32c0e5e4802976d70",
};

// @ts-ignore
export const PRIZE_ID = ENV === "dev" ? PRIZE_ID_DEV : PRIZE_ID_PROD;
export const DEFAULT_PAGE_SIZE = 10;

export const NFT_POTION_NAME = {
	1: "Premium",
	0: "Legendary",
};

export const NFT_POTION_TYPE = {
	PREMIUM: 1,
	LEGENDARY: 0,
};
