/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const accountWalletInfo = createSlice({
	name: 'accountWallet',
	initialState: {
		value: null,
	},
	reducers: {
		setWalletData: (state, action) => {
			state.value = action.payload.toString().toLowerCase();
		},
		clearWalletData: (state) => {
			state.value = null;
		},
	},
});

export const { setWalletData, clearWalletData } = accountWalletInfo.actions;
